<template>
  <div>
    <BarraCrud :botaoNovo="true" :onNovo="novo" :onFiltrar="filtrar" />

    <TiposDeDivulgacaoTable
      :filtro="filtro"
      :tipos="tipos"
      @editar="handleEditar($event)"
      @excluir="handleExcluir($event)"
      :loading="loading"
    />

    <b-modal id="modal-tipos-divulgacao" no-fade content-class="modalDireito">
      <template #modal-title>
        <i class="fas fa-syringe"></i>
        {{ typeof tipo_edit.id === "number" ? `Editando o tipo ${tipo_edit.nome}` : "Adicionar Tipo de Divulgação" }} 
      </template>
      <TiposDeDivulgacaoForm ref="TiposDeDivulgacaoForm" :tipo_edit="tipo_edit" @salvou="handleSalvou()" />
      <template #modal-footer={cancel} >
          <b-btn variant="primary" @click="$refs.TiposDeDivulgacaoForm.salvar()"> <i class="fas fa-save"></i> Salvar</b-btn>
          <b-btn @click="cancel"> <i class="fas fa-ban"></i> Cancelar</b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
import TiposDeDivulgacaoLib from "@/libs/TiposDeDivulgacaoLib.js";
import BarraCrud from "@/components/common/BarraCrud";
import TiposDeDivulgacaoTable from "@/components/Divulgacao/TiposDeDivulgacao/TiposDeDivulgacaoTable.vue";
import TiposDeDivulgacaoForm from "@/components/Divulgacao/TiposDeDivulgacao/TiposDeDivulgacaoForm.vue";

export default {
  components: {
    BarraCrud,
    TiposDeDivulgacaoTable,
    TiposDeDivulgacaoForm,
  },
  data() {
    return {
      filtro: "",
      tipos: [],
      tipo_edit: {},
      loading: false,
    };
  },
  methods: {
      limpar() {
      for (const key in this.tipo_edit) {
        this.tipo_edit[key] = "";
      }
    },
    novo() {
      this.limpar();
      this.$bvModal.show("modal-tipos-divulgacao");
    },
    filtrar(filtro) {
      this.filtro = filtro;
    },
    async handleSalvou(){
      this.$bvModal.hide('modal-tipos-divulgacao')
      await this.carregar()
    },
    handleEditar(tipo){
      this.$forceUpdate()
      Object.assign(this.tipo_edit, tipo)
      this.$bvModal.show('modal-tipos-divulgacao')
    },
    async handleExcluir(tipo){
      // console.log('vai deletar', tipo)
      if (tipo.id.toString() != '') {
        this.toastConfirmacao(async ()=>{
          try {
            let result = await TiposDeDivulgacaoLib.del(tipo)
            if (result) {
              this.showToast('success', 'Tipo excluído com suceso!')
              await this.carregar()
            }
          } catch (error) {
            this.showToast('error', 'Erro ao excluir tipo!\n' + error.message)
          }
        },
        ()=>{},)
      }
    },
    async carregar() {
      try {
      
        this.loading = true
        this.tipos = await TiposDeDivulgacaoLib.get();
      } catch (error) {
        console.log(error);
        this.showToast("error", error);
      }finally{
        this.loading = false
      }
    },
  },
  async mounted(){
    await this.carregar()
  }
};
</script>

<style>
</style>