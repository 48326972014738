<template>
  <div>
    <b-form @submit.prevent="salvar()">
      <div class="row">
        <div class="col">
          <b-form-group>
            <label>Nome</label>

            <b-form-input
              :state="validateState('nome')"
              v-model="$v.tipo.nome.$model"
              placeholder="Nome"
            ></b-form-input>
            <b-form-invalid-feedback
              >Esse campo é obrigatório.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import TiposDeDivulgacaoLib from "@/libs/TiposDeDivulgacaoLib.js";
import { validationMixin } from "vuelidate";
import { required /*, minLength*/ } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
      tipo_edit: {type: Object, default: ()=>{}}
  },
  mounted() {
      if (typeof this.tipo_edit.id != 'undefined' && this.tipo_edit.id.toString() != '') {
          this.$forceUpdate()
          Object.assign(this.tipo, this.tipo_edit)
      }
  },
  validations: {
    tipo: {
      nome: {
        required,
      },
    },
  },
  data() {
    return {
      tipo: {
        nome: ''
      },
      loading: false,
    };
  },
  watch: {},
  methods: {
     validateState(name) {
      const { $dirty, $error } = this.$v.tipo[name];
      return $dirty ? !$error : null;
    },
    async salvar() {
         this.$v.tipo.$touch();
      if (this.$v.tipo.$anyError) {
        return;
      }

   
        try {
          this.loading = true;
         
          let result = await TiposDeDivulgacaoLib.store(this.tipo);
          if (result.status == 201 && result.data) {
            this.$emit("salvou");
            this.resetForm()
            this.showToast("success", "Tipo de Divulgação salvo com sucesso!");
          } else {
            this.showToast("error", "Ocorreu algum erro inesperado :/");
          }
        } catch (error) {
          this.showToast("error", "Erro ao salvar\n" + error.message);
        } finally {
          this.loading = false;
        }
      
    },
    resetForm() {
      this.tipo = {
        nome: null,
        
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
   
};
</script>

<style lang="scss" scoped>
</style>