import axios from "@/utils/axios.js";

const get = async () => {
  let result;
  await axios
    .get("tipos_divulgacao/get")
    .then((data) => {
      // console.log(data)
      result = data.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return result;
};

const store = async (data) => {
  let result;
  await axios
    .post("tipos_divulgacao/store", data)
    .then(function(response) {
      result = response;
    })
    .catch(function(error) {
      throw new Error(error.response.data.error);
    });

  return result;
};

const del = async (data) => {
  let result;
  await axios
    .post("tipos_divulgacao/delete", data)
    .then((data) => {
      result = data;
    })
    .catch((err) => {
      throw new Error(err.response.data);
    });

  return result;
};

export default {
  get,
  store,
  del,
};
