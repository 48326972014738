<template>
  <div>
    <b-table
      stacked="sm"
      striped
      :busy="loading"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      :filter="filtro"
      responsive="sm"
      :fields="campos"
      show-empty
      :items="tipos"
    >
    <template #table-busy>
        <div class="text-center">
            <b-spinner small></b-spinner> Carregando...
        </div>
    </template>

     <template #empty>
       Nenhum tipo de divulgação cadastrado, clique em
          <b-btn variant="primary" size="sm" class="mx-2">
            <i class="fas fa-plus"></i> Tipos de Divulgação</b-btn
          >
          para cadastrar.
    </template>

      <template #cell(actions)="row">
        <b-btn
          variant="warning"
          class="m-1"
          @click="$emit('editar', row.item)"
          size="sm"
        >
          <i class="fas fa-edit"></i> Editar</b-btn
        >
        <b-btn
          variant="primary"
          class="m-1"
          @click="$emit('excluir', row.item)"
          size="sm"
        >
          <i class="fas fa-trash"></i> Excluir</b-btn
        >
      </template>
    </b-table>
        <b-pagination
      v-model="paginacao.pagina_atual"
      :total-rows="tipos.length"
      :per-page="paginacao.registros_por_pagina"
    >
    </b-pagination>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    tipos: { type: Array, default: () => [] },
    filtro: String,
    loading: {type: Boolean, default: false}
  },
  mounted() {},
  data() {
    return {
      campos: [
        { key: "id", label: "Código" },
        { key: "nome", label: "Nome", },
        { key: "actions", label: "", class: "text-right" },
      ],
        paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
    };
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>